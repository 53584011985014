<template>
  <div class="modal" role="dialog">
    <div>
      <h4>Восстановить пользователя {{ data }}?</h4>
    </div>
    <div class="buttons-wrap justify-end">
      <md-button
        class="md-raised md-success"
        type="submit"
        @click="blockUser()"
      >
        Подтвердить
      </md-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: String,
      default: "",
    },
  },
  methods: {
    async blockUser() {
      try {
        this.$notify({
          message: "Пользователь восстановлен",
          icon: "task_alt",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "primary",
        });
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: relative;
  height: auto;
  padding: 20px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 5px;
  background: #fff;
  min-width: 600px;
}
</style>
